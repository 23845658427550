import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {MposDate} from 'app/blocks/util/mpos-date';

export interface IInventoryTransaction extends IBaseEntity {
    id?: number;
    warehouse?: string;
    date?: MposDate;
    productName?: string;
    productCode?: string;
    startQty?: number;
    endQty?: number;
    operationQty?: number;
    operationType?: string;
    pickOrderId?: number;
    loadOrderId?: number;
    siteVisitId?: number;
    stockTakeId?: number;
    vehicleUnloadId?: number;
    incomingInventoryId?: number;
    warehouseTransferId?: number;
    documentRef?: string;
    loadOrderNo?: string;
    productCondition?: string;
    recipeId?: number;
    productionVoucherId?: number;
    productionVoucherName: string;
}

export class InventoryTransaction extends BaseEntity implements IInventoryTransaction {
    public id?: number;
    public warehouse?: string;
    public date?: MposDate;
    public productName?: string;
    public productCode?: string;
    public startQty?: number;
    public endQty?: number;
    public operationQty: number;
    public operationType?: string;
    public pickOrderId?: number;
    public loadOrderId?: number;
    public siteVisitId?: number;
    public stockTakeId?: number;
    public vehicleUnloadId?: number;
    public incomingInventoryId?: number;
    public warehouseTransferId?: number;
    public documentRef?: string;
    public loadOrderNo?: string;
    public productCondition?: string;
    public recipeId?: number;
    public productionVoucherId?: number;
    public productionVoucherName: string;

    constructor(inventoryTransaction?) {
        super();
        if (inventoryTransaction) {
            this.id = inventoryTransaction.id;
            this.warehouse = inventoryTransaction.warehouse;
            this.date = inventoryTransaction.date;
            this.productName = inventoryTransaction.productName;
            this.productCode = inventoryTransaction.productCode;
            this.startQty = inventoryTransaction.startQty;
            this.endQty = inventoryTransaction.endQty;
            this.operationQty = inventoryTransaction.operationQty;
            this.operationType = inventoryTransaction.operationType;
            this.pickOrderId = inventoryTransaction.pickOrderId;
            this.loadOrderId = inventoryTransaction.loadOrderId;
            this.siteVisitId = inventoryTransaction.siteVisitId;
            this.stockTakeId = inventoryTransaction.stockTakeId;
            this.vehicleUnloadId = inventoryTransaction.vehicleUnloadId;
            this.incomingInventoryId = inventoryTransaction.incomingInventoryId;
            this.warehouseTransferId = inventoryTransaction.warehouseTransferId;
            this.documentRef = inventoryTransaction.documentRef;
            this.loadOrderNo = inventoryTransaction.loadOrderNo;
            this.productCondition = inventoryTransaction.productCondition;
            this.recipeId = inventoryTransaction.recipeId;
            this.productionVoucherId = inventoryTransaction.productionVoucherId;
            this.productionVoucherName = inventoryTransaction.productionVoucherName;
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): InventoryTransaction {
        return new InventoryTransaction(this);
    }
}
